module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"LBA Créations Documents","short_name":"Docs","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/logo.png"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"lba-creations-documents","accessToken":"MC5YWklWSlJBQUFDTUFrMk5r.PO-_vQDvv73vv73vv70R77-9TjPvv71ZUmHvv73vv71F77-9Oe-_ve-_vUXvv73vv73vv70Z77-9ezIsGu-_vQ","path":"/preview","previews":true},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
